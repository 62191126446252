import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { HeroFinished, Content } from "page_components/investments"

const Investment = ({ data }) => {
  const title = "Zrealizowane projekty"

  return (
    <Layout
      seo={{
        title: title,
        descritpion:
          "Wysoka jakość, nowoczesny design i zadowolenie klientów to nasze priorytety. Sprawdź, jak wiele możemy zaoferować i przekonaj się, że warto inwestować z nami! ",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Portfel nieruchomości",
          path: "/portfel-nieruchomosci/",
        }}
      />
      <PageHeader title={title} />
      <HeroFinished count={data?.allWpInvestment?.nodes?.length} />
      <Content data={data?.allWpInvestment?.nodes} variant="done" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpInvestment(
      filter: { acfInvestment: { status: { eq: "done" } } }
      sort: { fields: acfInvestment___position, order: ASC }
    ) {
      nodes {
        id
        title
        uri
        slug
        acfInvestment {
          apartmensNumber
          areaNumberMin
          areaNumberMax
          apartmenPriceMin
          address
          status
          region
          logo {
            localFile {
              publicURL
            }
          }
          images {
            localFile {
              publicURL
            }
          }
          excerpt
          mapContent {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default Investment
